import { Injectable } from '@angular/core';
import { ScriptStore } from '../contants/scripts-store.constant';
import { Script } from '@core/interfaces/file-to-load.interface';

@Injectable({
  providedIn: 'root',
})
export class FileLoaderService {
  private scripts: { [key: string]: Script } = {};

  constructor() {
    ScriptStore.forEach((script: Script) => {
      this.scripts[script.name] = {
        name: script.name,
        loaded: false,
        src: script.src,
      };
      if (script.chat) {
        this.scripts[script.name].chat = true;
      }
      if (script.widgetId) {
        this.scripts[script.name].widgetId = script.widgetId;
      }
    });
  }

  public load(...scripts: string[]): Promise<{ script: string; loaded: boolean; status: string }[]> {
    const promises: Promise<{ script: string; loaded: boolean; status: string }>[] = [];
    scripts.forEach((script: string) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string): Promise<{ script: string; loaded: boolean; status: string }> {
    return new Promise((resolve, _reject) => {
      // resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        script.id = name;
        if (this.scripts[name] && this.scripts[name].chat) {
          script.setAttribute('chat', 'true');
        }
        if (this.scripts[name] && this.scripts[name].widgetId) {
          script.setAttribute('widgetId', this.scripts[name].widgetId as string);
        }

        script.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        script.onerror = (_error: string | Event) => resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  public removeScript(name: string): Promise<{ script: string; removed: boolean; status: string }> {
    return new Promise((resolve, reject) => {
      if (this.scripts[name].loaded) {
        const script = document.getElementById(name);
        if (script) {
          script.remove();
          this.scripts[name].loaded = false; // Update the loaded status
          resolve({ script: name, removed: true, status: 'Removed' });
        } else {
          reject({ script: name, removed: false, status: 'Script not found' });
        }
      } else {
        resolve({ script: name, removed: false, status: 'Script not loaded' });
      }
    });
  }

  // Add check for script loaded by name
  public isScriptLoaded(name: string): boolean {
    return this.scripts[name]?.loaded ?? false;
  }
}
