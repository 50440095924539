import { inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Logout } from '../../auth/store/actions/auth.actions';
import { State } from '../../auth/store';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorService {
  constructor(
    private store: Store<State>,
    private _snackBar: MatSnackBar
  ) {}

  handleError(
    // response: HttpErrorResponse | HttpResponse<any>,
    response: HttpErrorResponse,
    url: string
  ): Observable<HttpEvent<any>> {
    switch (response.status) {
      case 0:
        const resp = response.error;
        return throwError(
          () =>
            new Error({
              ...resp,
              message: 'No internet connection',
            })
        );

      case 302: // handle session timeout
        this.store.dispatch(Logout());
        // return throwError(() => new Error(response.error));
        return throwError(() => response.error);

      case 401: // Unauthorized
        this.store.dispatch(Logout());
        this._snackBar.open('You are not authorized to perform this action (401 Unauthorized)', '', {
          verticalPosition: 'top',
          horizontalPosition: 'right',
          duration: 10000,
          panelClass: ['snackbar-error'],
        });
        return throwError(() => response.error);

      case 403: // Forbidden
        // this.store.dispatch(Logout());
        const error = {
          ...response.error,
          message: 'You are not authorized to perform this action (403 Forbidden)',
        };

        return throwError(() => error);

      default:
        return throwError(() => response.error);
    }
  }
}
