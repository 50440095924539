import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@auth/store';
import { selectLoginError, selectLoginLoading } from '../../store/selectors/login.selectors';
import { Login } from '../../store/actions/auth.actions';
import { Observable } from 'rxjs';
import { FileLoaderService } from '@core/services/file-loader.service';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLoginComponent {
  public readonly loading$: Observable<boolean>;
  public readonly error$: Observable<Error | null>;
  public loginModel: { username: string; password: string } = { username: '', password: '' };
  public passFieldType: 'text' | 'password' = 'password';

  constructor(
    private store: Store<State>,
    private fileLoaderService: FileLoaderService
  ) {
    this.loading$ = this.store.select(selectLoginLoading);
    this.error$ = this.store.select(selectLoginError);
    // Load new script
    this.fileLoaderService.load('chatLoggedOut').then((res) => {
      // console.log('chatLoggedOut Loaded', res);
    });
  }

  public toggleShowPass(): void {
    this.passFieldType = this.passFieldType === 'password' ? 'text' : 'password';
  }

  public login(username: string, password: string): void {
    this.store.dispatch(Login({ username, password }));
  }
}
